import React, { useState, useEffect } from 'react';
import { StyleSheet, ScrollView, Text, Dimensions, Image, TouchableOpacity, View, SafeAreaView, FlatList, TextInput } from 'react-native';
import IconImages from './IconImages';
import { TabView, TabBar } from 'react-native-tab-view';
import * as AppConst from './AppConst';
import * as CmnUtil from './CmnUtil';
import * as SettingData from './SettingData';
import ImageZoom from 'react-native-image-pan-zoom';
import { Checkbox } from 'react-native-paper';

const ScreenResult = ({ route, navigation }: { route: any, navigation: any }) => {

  // 診断情報
  const [info, setInfo] = useState<any>(undefined);
  // 診断結果情報
  const [results, setResults] = useState<any>(undefined);
  // 選択グループ（矩形の番号）
  const [resultIndex, setResultIndex] = useState(0);
  // 選択順位（タブの番号）
  const [candidateIndex, setCandidateIndex] = useState(0);
  const [candidate, setCandidate] = useState<{ key: string; title: string; }[]>([]);
  // タブ再描画用変数
  const [candidateFlush, setCandidateFlush] = useState(false);
  // 画像描画領域サイズ
  const [imageViewSize, setImageViewSize] = useState({ width: 0, height: 0 });
  // 診断情報表示フラグ
  const [dianosisInfo, setDianosisInfo] = useState(true);
  // モーダル画像表示
  const [imageModal, setImageModal] = useState<undefined | string>(undefined);
  // 農薬フィルタ表示
  const [showFiller, setShowFiller] = useState(false);
  // 農薬フィルタ
  const [pesticideFillter, setPesticideFillter] = useState<{
    name: string;
    company: string;
    use: string;
    active_ingredient: string[];
    irac_code: string[];
    frac_code: string[];
    dosage_form: string[];
  }>({
    name: '',
    company: '',
    use: '指定なし',
    active_ingredient: [],
    irac_code: [],
    frac_code: [],
    dosage_form: [],
  });
  // 用途リスト
  const [useList, setUseList] = useState<string[]>([]);
  // 有効成分リスト
  const [activeIngredientList, setActiveIngredient] = useState<string[]>([]);
  // IRACコードリスト
  const [iracCodeList, setIracCodeList] = useState<string[]>([]);
  // FRACコードリスト
  const [fracCodeList, setFracCodeList] = useState<string[]>([]);
  // 剤型名リスト
  const [dosageFormList, setDosageForm] = useState<string[]>([]);

  useEffect(() => {
    setInfo(route.params.info);
    setResults(route.params.results);
    const tmp_candidate = [];
    const tmp_useList = ['指定なし'];
    const tmp_activeIngredientList = [];
    const tmp_iracCodeList = [];
    const tmp_fracCodeList = [];
    const tmp_dosageFormList = [];
    for (let i = 0; i < route.params.results[resultIndex].candidates.length; i++) {
      const tmp = route.params.results[resultIndex].candidates[i];
      tmp_candidate[i] = { key: i.toString(), title: CmnUtil.formatRatio(tmp.probability) };
    }
    setCandidate(tmp_candidate);

    // 農薬フィルタ用の選択肢作成
    for (let i = 0; i < route.params.results.length; i++) {
      const tmpResult = route.params.results[i];
      for (let j = 0; j < tmpResult.candidates.length; j++) {
        const tmpCandidate = tmpResult.candidates[j];
        for (let k = 0; k < tmpCandidate.pesticide.length; k++) {
          const tmpPesticide = tmpCandidate.pesticide[k];
          if (tmpPesticide.use != undefined && tmp_useList.indexOf(tmpPesticide.use) < 0) {
            tmp_useList.push(tmpPesticide.use);
          }
          if (tmpPesticide.active_ingredient != undefined) {
            for (let m = 0; m < tmpPesticide.active_ingredient.length; m++) {
              const tmpActiveIngredient = tmpPesticide.active_ingredient[m];
              if (tmp_activeIngredientList.indexOf(tmpActiveIngredient) < 0) {
                tmp_activeIngredientList.push(tmpActiveIngredient);
              }
            }
          }
          if (tmpPesticide.irac_code != undefined) {
            for (let m = 0; m < tmpPesticide.irac_code.length; m++) {
              const tmpIracCode = tmpPesticide.irac_code[m];
              if (tmp_iracCodeList.indexOf(tmpIracCode) < 0) {
                tmp_iracCodeList.push(tmpIracCode);
              }
            }
          }
          if (tmpPesticide.frac_code != undefined) {
            for (let m = 0; m < tmpPesticide.frac_code.length; m++) {
              const tmpFracCode = tmpPesticide.frac_code[m];
              if (tmp_fracCodeList.indexOf(tmpFracCode) < 0) {
                tmp_fracCodeList.push(tmpFracCode);
              }
            }
          }
          if (tmpPesticide.dosage_form != undefined && tmp_dosageFormList.indexOf(tmpPesticide.dosage_form) < 0) {
            tmp_dosageFormList.push(tmpPesticide.dosage_form);
          }
        }
      }
    }
    setUseList(tmp_useList);
    setActiveIngredient(tmp_activeIngredientList);
    setIracCodeList(tmp_iracCodeList);
    setFracCodeList(tmp_fracCodeList);
    setDosageForm(tmp_dosageFormList);

    setCandidateFlush(true);
  }, []);

  useEffect(() => {
    // ヘッダの診断情報表示ボタン
    navigation.setOptions({
      headerRight: () => (
        <TouchableOpacity style={{ marginRight: 20 }}
          onPress={() => setDianosisInfo(!dianosisInfo)}>
          {dianosisInfo && <Image style={{ width: 30, height: 30 }} resizeMode='cover' source={IconImages.icImageInfoInvisible} />}
          {!dianosisInfo && <Image style={{ width: 30, height: 30 }} resizeMode='cover' source={IconImages.icImageInfoVisible} />}
        </TouchableOpacity>
      ),
    });
  }, [dianosisInfo]);

  const renderTabBar = (props: any) => (
    <TabBar
      {...props}
      style={{ backgroundColor: 'white' }}
      indicatorStyle={{ backgroundColor: AppConst.defaultColor }}
      activeColor={AppConst.defaultColor}
      inactiveColor='#aaa'
      scrollEnabled={false}
      tabStyle={{ height: 50 }}
    />
  );

  // 診断結果表示
  const resultView = ({ route }: { route: any }) => {
    if (results == undefined) {
      return (<></>);
    }
    const candidate = results[resultIndex].candidates[route.key];
    return (
      <>
        {/* 診断結果 */}
        <View style={{ margin: 20, borderBottomWidth: 2, borderBottomColor: "black" }}>
          <Text style={{ fontSize: 26, borderBottomColor: '#4a4a4a' }}>{candidate.estimated}</Text>
        </View>

        {/* サンプル画像 */}
        {sampleImageView(candidate.photos)}

        {/* 農薬紹介 */}
        {candidate.pesticide.length > 0 && <>
          <TouchableOpacity style={{ marginTop: 0 }} onPress={() => { setShowFiller(true); }}>
            <Text style={{ fontSize: 18, padding: 5, textAlign: 'center', backgroundColor: '#dddddd', color: '#4a4a4a' }}>この病害虫に有効な農薬を紹介します。</Text>
            <Text style={{ fontSize: 18, padding: 5, textAlign: 'center', backgroundColor: '#dddddd', color: '#4a4a4a' }}>（タップで絞り込み）</Text>
          </TouchableOpacity>
          {candidate.pesticide.map((data: any, index: number) => (pesticidesView(data, index)))}
        </>}

        {candidate.pesticide.length == 0 && <>
          <View style={{ marginTop: 0 }}>
            <Text style={{ fontSize: 18, padding: 5, textAlign: 'center', backgroundColor: '#dddddd', color: '#4a4a4a' }}>紹介可能な農薬情報が登録されていません。</Text>
          </View>
        </>}

      </>
    );
  }

  // 参考画像表示
  const sampleImageView = (photos: any) => {
    let imageArray = [];
    for (let i = 0; i < photos.length; i++) {
      imageArray.push(
        <TouchableOpacity key={i} onPress={() => { setImageModal(photos[i].original) }} style={{ paddingBottom: 20 }}>
          <Image style={[{ width: 150, height: 150, marginLeft: 20 }]} resizeMode='cover' source={{ uri: photos[i].thumbnail }} />
        </TouchableOpacity>
      );
    }
    return <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>{imageArray}</View>;
  }

  // 農薬表示
  const pesticidesView = (pesticide: any, index: number) => {
    // 農薬名の絞り込み
    if (pesticideFillter.name != '' && pesticide.name.indexOf(pesticideFillter.name) < 0) {
      return (<View key={index}></View>);
    }
    // 会社名の絞り込み
    if (pesticideFillter.company != '' && pesticide.company.indexOf(pesticideFillter.company) < 0) {
      return (<View key={index}></View>);
    }
    // 用途の絞り込み
    if (pesticideFillter.use != '指定なし' && pesticide.use != pesticideFillter.use) {
      return (<View key={index}></View>);
    }
    // IRACコードの絞り込み
    if (pesticideFillter.irac_code.length > 0) {
      let i = 0;
      for (; i < pesticideFillter.irac_code.length; i++) {
        const tmpPesticide = pesticideFillter.irac_code[i];
        if (pesticide.irac_code.indexOf(tmpPesticide) >= 0) {
          break;
        }
      }
      if (i >= pesticideFillter.irac_code.length) {
        return (<View key={index}></View>);
      }
    }
    // FRACコードの絞り込み
    if (pesticideFillter.frac_code.length > 0) {
      let i = 0;
      for (; i < pesticideFillter.frac_code.length; i++) {
        const tmpPesticide = pesticideFillter.frac_code[i];
        if (pesticide.frac_code.indexOf(tmpPesticide) >= 0) {
          break;
        }
      }
      if (i >= pesticideFillter.frac_code.length) {
        return (<View key={index}></View>);
      }
    }
    // 有効成分の絞り込み
    if (pesticideFillter.active_ingredient.length > 0) {
      let i = 0;
      for (; i < pesticideFillter.active_ingredient.length; i++) {
        const tmpPesticide = pesticideFillter.active_ingredient[i];
        if (pesticide.active_ingredient.indexOf(tmpPesticide) >= 0) {
          break;
        }
      }
      if (i >= pesticideFillter.active_ingredient.length) {
        return (<View key={index}></View>);
      }
    }
    // 剤型名の絞り込み
    if (pesticideFillter.dosage_form.length > 0) {
      let i = 0;
      for (; i < pesticideFillter.dosage_form.length; i++) {
        const tmpPesticide = pesticideFillter.dosage_form[i];
        if (pesticide.dosage_form == tmpPesticide) {
          break;
        }
      }
      if (i >= pesticideFillter.dosage_form.length) {
        return (<View key={index}></View>);
      }
    }

    // 色の設定
    let boxColor = AppConst.pesticideBoxes.slice(-1)[0].color.box;
    let textColor = AppConst.pesticideBoxes.slice(-1)[0].color.text;
    for (const pesticideBox of AppConst.pesticideBoxes) {
      if (pesticideBox.use == pesticide.use) {
        boxColor = pesticideBox.color.box;
        textColor = pesticideBox.color.text;
        break;
      }
    }

    return (
      <View key={index}>
        <View style={{ paddingLeft: 20 }}>
          <Text style={[AppConst.styles.fontSizeL, { marginTop: 5 }]}>{pesticide.name}</Text>
          <Text style={{ marginTop: 5 }}>{pesticide.company}</Text>
          {pesticide.dosage_form != undefined &&
            <Text style={{ marginTop: 5 }}>剤型：{pesticide.dosage_form}</Text>}
          {pesticide.active_ingredient != undefined && pesticide.active_ingredient.length > 0 &&
            <Text style={{ marginTop: 5 }}>有効成分：{pesticide.active_ingredient.join(', ')}</Text>}
          <TouchableOpacity
            style={{ backgroundColor: '#cc2f18', width: '40%', borderRadius: 10, padding: 10, marginTop: 5 }}
            onPress={() => onClickPesticides(pesticide.url)}>
            <View style={styles.center}>
              <Text style={styles.pesticideButton}>詳細はこちら</Text>
            </View>
          </TouchableOpacity>
          {pesticide.use != undefined && <View style={{ borderRadius: 10, backgroundColor: boxColor, minWidth: 130, position: 'absolute', right: 10, top: 10 }}>
            <Text style={[styles.pesticideBox, { marginTop: 5, color: textColor }]}>{pesticide.use}</Text>
            {pesticide.irac_code.length > 0 && <Text style={[styles.pesticideBox, { color: textColor }]}>[{pesticide.irac_code.join(', ')}]</Text>}
            {pesticide.frac_code.length > 0 && <Text style={[styles.pesticideBox, { color: textColor }]}>[{pesticide.frac_code.join(', ')}]</Text>}
          </View>}
        </View>
        <View style={{ marginTop: 10, height: 10, borderTopWidth: 1, borderColor: '#bdbdbd' }} />
      </View>
    );
  }

  // 農薬ボタンクリック
  const onClickPesticides = (url: string) => {
    CmnUtil.moveWebPage(url);
  }

  // 選択グループ変更
  const changeResultIndex = (index: number) => {
    setResultIndex(index);
    const tmp_candidate = [];
    for (let i = 0; i < results[index].candidates.length; i++) {
      const tmp = results[index].candidates[i];
      tmp_candidate[i] = { key: i.toString(), title: CmnUtil.formatRatio(tmp.probability) };
    }
    setCandidate(tmp_candidate);
    setCandidateFlush(false);
    setCandidateFlush(true);
    setCandidateIndex(0);
  }

  // 矩形表示
  const bboxView = () => {
    if (results == undefined) {
      return (<></>);
    }
    const tmp_bboxView = [];

    // 座標0,0に近いものに並べ替え
    const sortIndexList = sortBBox();

    for (let i = 0; i < results.length; i++) {

      const sortIndex = sortIndexList[i];
      const bbox = results[sortIndex].bbox;
      if (bbox != undefined && imageViewSize.width != 0
        && bbox.length != 0 && bbox[0] != undefined && bbox[0] != null) {
        // 座標計算
        const baseAsp = imageViewSize.width / imageViewSize.height;
        const imgAsp = info.photoW / info.photoH;
        let rete = 1;
        let xMargin = 0;
        let yMargin = 0;
        if (baseAsp < imgAsp) {
          // 上下に余白
          rete = imageViewSize.width / info.photoW;
          yMargin = (imageViewSize.height - info.photoH * rete) / 2;
        } else {
          // 左右に余白
          rete = imageViewSize.height / info.photoH;
          xMargin = (imageViewSize.width - info.photoW * rete) / 2;
        }
        const tmp_width = (bbox[2] - bbox[0]) * rete;
        const tmp_height = (bbox[3] - bbox[1]) * rete;
        const tmp_left = bbox[0] * rete + xMargin;
        const tmp_top = bbox[1] * rete + yMargin;

        // 矩形View
        tmp_bboxView.push(
          <TouchableOpacity key={sortIndex} style={[resultIndex == sortIndex ? { borderColor: 'blue' } : { borderColor: 'gray' },
          { borderWidth: 2, height: tmp_height, width: tmp_width, position: 'absolute', left: tmp_left, top: tmp_top }]}
            onPress={() => changeResultIndex(sortIndex)}></TouchableOpacity>
        )
      }
    }

    return <>{tmp_bboxView}</>;
  }

  // bboxを座標0,0に近いものから並べ替えたIndexを返却
  const sortBBox = () => {
    const indexList = [];
    let tmpIndex = 0;
    for (let i = 0; i < results.length; i++) {
      indexList.push(i);
    }
    indexList.sort((a, b) => {
      let comp = 0;
      if (results[a].bbox == undefined || results[b].bbox == undefined ||
        results[a].bbox.length == 0 || results[b].bbox.length == 0 ||
        results[a].bbox[0] == undefined || results[b].bbox[0] == undefined ||
        results[a].bbox[0] == null || results[b].bbox[0] == null
      ) {
        return comp;
      }

      comp = results[a].bbox[0] - results[b].bbox[0];
      if (comp == 0) {
        comp = results[a].bbox[1] - results[b].bbox[1];
      }
      return comp;
    });

    return indexList;
  }

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView style={styles.scrollView}>
        <View style={styles.photo} onLayout={(e) => { setImageViewSize({ width: e.nativeEvent.layout.width, height: e.nativeEvent.layout.height }) }}>
          {info && <TouchableOpacity onPress={() => { setImageModal(info.data) }} activeOpacity={0.9}><Image style={styles.image} source={{ uri: info.data }} /></TouchableOpacity>}
          {dianosisInfo && info && <View style={styles.dianosisInfo}>
            {info.id && <Text style={styles.dianosisInfoText}>診断ＩＤ：{info.id}</Text>}
            {info.photo_date && <Text style={styles.dianosisInfoText}>撮影日時：{info.photo_date}</Text>}
            {info.category && <Text style={styles.dianosisInfoText}>診断対象：{info.category}</Text>}
            {info.plant && <Text style={styles.dianosisInfoText}>対象作物：{info.plant}</Text>}
            {info.part && <Text style={styles.dianosisInfoText}>部　位　：{info.part}</Text>}
            {info.answer && SettingData.getMode() === 'teacher' && <Text style={styles.dianosisInfoText}>正　解　：{info.answer}</Text>}
          </View>}
          {bboxView()}
        </View>
        <View style={styles.detail}>
          {candidateFlush && <TabView
            navigationState={{ index: candidateIndex, routes: candidate }}
            renderScene={resultView}
            onIndexChange={setCandidateIndex}
            renderTabBar={renderTabBar}
          />}
        </View>
      </ScrollView>
      {imageModal && <View style={styles.modal} >
        <ImageZoom cropWidth={Dimensions.get('window').width}
          cropHeight={Dimensions.get('window').height}
          imageWidth={Dimensions.get('window').width}
          imageHeight={Dimensions.get('window').height}>
          <Image style={{ width: Dimensions.get('window').width, height: Dimensions.get('window').height }}
            resizeMode='contain'
            source={{ uri: imageModal }} />
        </ImageZoom>
        <TouchableOpacity style={{ position: 'absolute', top: 30, right: 30 }} onPress={() => { setImageModal(undefined) }}>
          <Image style={{ width: 30, height: 30 }}
            resizeMode='contain'
            source={IconImages.icClose} />
        </TouchableOpacity>
      </View>}

      {/* 農薬フィルタ表示 */}
      {showFiller && <FillterView
        useList={useList}
        activeIngredientList={activeIngredientList}
        iracCodeList={iracCodeList}
        fracCodeList={fracCodeList}
        dosageFormList={dosageFormList}
        pesticideFillter={pesticideFillter}
        setPesticideFillter={setPesticideFillter}
        setShowFiller={setShowFiller} />}
    </SafeAreaView>
  );
}

// 農薬フィルタ表示
const FillterView = ({ useList, activeIngredientList, iracCodeList, fracCodeList, dosageFormList, pesticideFillter, setPesticideFillter, setShowFiller }: { useList: any, activeIngredientList: any, iracCodeList: any, fracCodeList: any, dosageFormList: any, pesticideFillter: any, setPesticideFillter: Function, setShowFiller: Function }) => {
  const [tmpFillter, setTmpFillter] = useState<{
    name: string;
    company: string;
    use: string;
    active_ingredient: string[];
    irac_code: string[];
    frac_code: string[];
    dosage_form: string[];
  }>(pesticideFillter);
  // モーダル選択内容
  const [selectModal, setSelectModal] = useState<undefined | string>(undefined);
  const [modalItem, setModalItem] = useState<string[]>([]);

  // モーダル表示
  const renderItemFillter = ({ item, index }: { item: any, index: number }) => {
    return (
      <TouchableOpacity onPress={() => selectItem(item)}
        style={[{ padding: 10, borderColor: '#bdbdbd' }, index !== modalItem!.length - 1 && { borderBottomWidth: 1 }]} >
        <Text style={[AppConst.styles.fontSizeLL, { paddingBottom: 5 }]}>{item}</Text>
      </TouchableOpacity>
    );
  }

  // 複数モーダル表示
  const renderItemMultiFillter = ({ item, index }: { item: any, index: number }) => {
    if (selectModal == 'active_ingredient') {
      // 有効成分
      const index2 = tmpFillter.active_ingredient.indexOf(item);
      return (
        <TouchableOpacity key={index} style={[{ flexDirection: 'row', padding: 10, borderColor: '#bdbdbd' }, index !== modalItem!.length - 1 && { borderBottomWidth: 1 }]} activeOpacity={1.0} onPress={() => {
          if (index2 < 0) {
            setTmpFillter({ ...tmpFillter, active_ingredient: [...tmpFillter.active_ingredient, item] });
          } else {
            setTmpFillter({ ...tmpFillter, active_ingredient: [...(tmpFillter.active_ingredient.slice(0, index2)), ...(tmpFillter.active_ingredient.slice(index2 + 1))] });
          }
        }}>
          <View style={styles.ckeckBox}>
            <Checkbox status={index2 < 0 ? 'unchecked' : 'checked'} color={AppConst.defaultColor} />
          </View>
          <View style={[styles.ckeckBox, { width: '100%' }]}>
            <Text style={[AppConst.styles.fontSizeLL, { width: '100%' }]}>{item}</Text>
          </View>
        </TouchableOpacity>
      );
    } else if (selectModal == 'irac_code') {
      // IRACコード
      const index2 = tmpFillter.irac_code.indexOf(item);
      return (
        <TouchableOpacity key={index} style={[{ flexDirection: 'row', padding: 10, borderColor: '#bdbdbd' }, index !== modalItem!.length - 1 && { borderBottomWidth: 1 }]} activeOpacity={1.0} onPress={() => {
          if (index2 < 0) {
            setTmpFillter({ ...tmpFillter, irac_code: [...tmpFillter.irac_code, item] });
          } else {
            setTmpFillter({ ...tmpFillter, irac_code: [...(tmpFillter.irac_code.slice(0, index2)), ...(tmpFillter.irac_code.slice(index2 + 1))] });
          }
        }}>
          <View style={styles.ckeckBox}>
            <Checkbox status={index2 < 0 ? 'unchecked' : 'checked'} color={AppConst.defaultColor} />
          </View>
          <View style={[styles.ckeckBox, { width: '100%' }]}>
            <Text style={[AppConst.styles.fontSizeLL, { width: '100%' }]}>{item}</Text>
          </View>
        </TouchableOpacity>
      );
    } else if (selectModal == 'frac_code') {
      // FRACコード
      const index2 = tmpFillter.frac_code.indexOf(item);
      return (
        <TouchableOpacity key={index} style={[{ flexDirection: 'row', padding: 10, borderColor: '#bdbdbd' }, index !== modalItem!.length - 1 && { borderBottomWidth: 1 }]} activeOpacity={1.0} onPress={() => {
          if (index2 < 0) {
            setTmpFillter({ ...tmpFillter, frac_code: [...tmpFillter.frac_code, item] });
          } else {
            setTmpFillter({ ...tmpFillter, frac_code: [...(tmpFillter.frac_code.slice(0, index2)), ...(tmpFillter.frac_code.slice(index2 + 1))] });
          }
        }}>
          <View style={styles.ckeckBox}>
            <Checkbox status={index2 < 0 ? 'unchecked' : 'checked'} color={AppConst.defaultColor} />
          </View>
          <View style={[styles.ckeckBox, { width: '100%' }]}>
            <Text style={[AppConst.styles.fontSizeLL, { width: '100%' }]}>{item}</Text>
          </View>
        </TouchableOpacity>
      );
    } else if (selectModal == 'dosage_form') {
      // 剤型名
      const index2 = tmpFillter.dosage_form.indexOf(item);
      return (
        <TouchableOpacity key={index} style={[{ flexDirection: 'row', padding: 10, borderColor: '#bdbdbd' }, index !== modalItem!.length - 1 && { borderBottomWidth: 1 }]} activeOpacity={1.0} onPress={() => {
          if (index2 < 0) {
            setTmpFillter({ ...tmpFillter, dosage_form: [...tmpFillter.dosage_form, item] });
          } else {
            setTmpFillter({ ...tmpFillter, dosage_form: [...(tmpFillter.dosage_form.slice(0, index2)), ...(tmpFillter.dosage_form.slice(index2 + 1))] });
          }
        }}>
          <View style={styles.ckeckBox}>
            <Checkbox status={index2 < 0 ? 'unchecked' : 'checked'} color={AppConst.defaultColor} />
          </View>
          <View style={[styles.ckeckBox, { width: '100%' }]}>
            <Text style={[AppConst.styles.fontSizeLL, { width: '100%' }]}>{item}</Text>
          </View>
        </TouchableOpacity>
      );
    } else {
      return (
        <></>
      );
    }
  }

  // モーダル内アイテム選択
  const selectItem = (item: string) => {
    if (selectModal == 'use') {
      setTmpFillter({ ...tmpFillter, use: item });
    }
    setSelectModal(undefined);
  }

  return (
    <View style={styles.fillter}>
      <View style={{ backgroundColor: 'white', width: '90%', borderRadius: 5, padding: 20 }}>
        <Text style={AppConst.styles.fontSizeL}>絞り込み</Text>

        <Text style={styles.itemText}>農薬名</Text>
        <TextInput
          style={[AppConst.styles.fontSizeLL, styles.dropdownText, { borderBottomWidth: 1, flexDirection: 'row', minHeight: 30, width: '100%' }]}
          textContentType='none'
          autoCapitalize='none'
          onChangeText={(text) => setTmpFillter({ ...tmpFillter, name: text })}
          value={tmpFillter.name} />

        <Text style={styles.itemText}>会社名</Text>
        <TextInput
          style={[AppConst.styles.fontSizeLL, styles.dropdownText, { borderBottomWidth: 1, flexDirection: 'row', minHeight: 30, width: '100%' }]}
          textContentType='none'
          autoCapitalize='none'
          onChangeText={(text) => setTmpFillter({ ...tmpFillter, company: text })}
          value={tmpFillter.company} />

        <Text style={styles.itemText}>用途</Text>
        <TouchableOpacity style={{ borderBottomWidth: 1, flexDirection: 'row', minHeight: 30 }}
          onPress={() => { setModalItem(useList); setSelectModal('use'); }}>
          <Text style={[AppConst.styles.fontSizeLL, styles.dropdownText]} numberOfLines={2}>{tmpFillter.use}</Text>
          <Image style={styles.dropdownImage} source={IconImages.icDropdown} />
        </TouchableOpacity>

        <Text style={styles.itemText}>有効成分</Text>
        <TouchableOpacity style={{ borderBottomWidth: 1, flexDirection: 'row', minHeight: 30 }}
          onPress={() => { setModalItem(activeIngredientList); setSelectModal('active_ingredient'); }}>
          <Text style={[AppConst.styles.fontSizeLL, styles.dropdownText]} numberOfLines={1}>{tmpFillter.active_ingredient.length == 0 ? '指定なし' : tmpFillter.active_ingredient.join(',')}</Text>
          <Image style={styles.dropdownImage} source={IconImages.icDropdown} />
        </TouchableOpacity>

        <Text style={styles.itemText}>IRACコード</Text>
        <TouchableOpacity style={{ borderBottomWidth: 1, flexDirection: 'row', minHeight: 30 }}
          onPress={() => { setModalItem(iracCodeList); setSelectModal('irac_code'); }}>
          <Text style={[AppConst.styles.fontSizeLL, styles.dropdownText]} numberOfLines={1}>{tmpFillter.irac_code.length == 0 ? '指定なし' : tmpFillter.irac_code.join(',')}</Text>
          <Image style={styles.dropdownImage} source={IconImages.icDropdown} />
        </TouchableOpacity>

        <Text style={styles.itemText}>FRACコード</Text>
        <TouchableOpacity style={{ borderBottomWidth: 1, flexDirection: 'row', minHeight: 30 }}
          onPress={() => { setModalItem(fracCodeList); setSelectModal('frac_code'); }}>
          <Text style={[AppConst.styles.fontSizeLL, styles.dropdownText]} numberOfLines={1}>{tmpFillter.frac_code.length == 0 ? '指定なし' : tmpFillter.frac_code.join(',')}</Text>
          <Image style={styles.dropdownImage} source={IconImages.icDropdown} />
        </TouchableOpacity>

        <Text style={styles.itemText}>剤型名</Text>
        <TouchableOpacity style={{ borderBottomWidth: 1, flexDirection: 'row', minHeight: 30 }}
          onPress={() => { setModalItem(dosageFormList); setSelectModal('dosage_form'); }}>
          <Text style={[AppConst.styles.fontSizeLL, styles.dropdownText]} numberOfLines={1}>{tmpFillter.dosage_form.length == 0 ? '指定なし' : tmpFillter.dosage_form.join(',')}</Text>
          <Image style={styles.dropdownImage} source={IconImages.icDropdown} />
        </TouchableOpacity>

        <View style={{ flexDirection: 'row', paddingTop: 20 }}>
          <TouchableOpacity style={[styles.selectButton, { backgroundColor: '#4a4a4a' }]} onPress={() => { setShowFiller(false) }}>
            <View style={styles.center}>
              <Text style={styles.operationBackNextButton}>キャンセル</Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity style={[styles.selectButton, { backgroundColor: AppConst.defaultColor }]} onPress={() => {
            setPesticideFillter({ ...tmpFillter });
            setShowFiller(false);
          }}>
            <View style={styles.center}>
              <Text style={styles.operationBackNextButton}>OK</Text>
            </View>
          </TouchableOpacity>
        </View>

      </View>

      {/* 選択ダイアログ */}
      {selectModal == 'use' && <TouchableOpacity style={styles.modal} activeOpacity={1.0} onPress={() => { setSelectModal(undefined) }}>
        <View style={{ width: '90%', height: '65%', marginTop: 150, marginBottom: 150, backgroundColor: 'white', borderRadius: 5 }}>
          {modalItem.length <= 0 && <Text style={[AppConst.styles.fontSizeLL, { padding: 20 }]}>選択肢がありません。</Text>}
          <FlatList
            data={modalItem}
            renderItem={renderItemFillter}
            keyExtractor={(item: any, index: number) => index.toString()}
          />
        </View>
      </TouchableOpacity>}

      {/* 複数選択ダイアログ */}
      {(selectModal == 'active_ingredient' || selectModal == 'dosage_form' || selectModal == 'irac_code' || selectModal == 'frac_code')
        && <View style={styles.modal} >
          <View style={{ width: '90%', height: '65%', marginTop: 150, marginBottom: 150, backgroundColor: 'white', borderRadius: 5 }}>
            {modalItem.length <= 0 && <Text style={[AppConst.styles.fontSizeLL, { padding: 20 }]}>選択肢がありません。</Text>}
            <FlatList
              data={modalItem}
              renderItem={renderItemMultiFillter}
              keyExtractor={(item: any, index: number) => index.toString()}
            />
            <View style={{ flexDirection: 'row', padding: 20, borderTopWidth: 1, borderColor: '#bdbdbd' }}>
              <TouchableOpacity style={[styles.selectButton, { backgroundColor: AppConst.defaultColor }]} onPress={() => {
                setSelectModal(undefined);
              }}>
                <View style={styles.center}>
                  <Text style={styles.operationBackNextButton}>OK</Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </View>}

    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollView: {
    flex: 1,
  },
  photo: {
    height: 350,
    backgroundColor: 'black',
  },
  image: {
    width: '100%',
    height: 350,
    resizeMode: 'contain',
  },
  dianosisInfo: {
    position: 'absolute',
    marginLeft: 10,
    bottom: 10,
  },
  dianosisInfoText: {
    color: 'white',
    fontSize: AppConst.styles.fontSizeM.fontSize,
    textShadowColor: 'black',
    textShadowRadius: 2,
  },
  detail: {
    flex: 1,
  },
  center: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  pesticideButton: {
    color: 'white',
  },
  modal: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
  fillter: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
  itemText: {
    marginTop: 20,
    fontSize: AppConst.styles.fontSizeS.fontSize
  },
  dropdownText: {
    textAlignVertical: 'bottom',
    width: '85%'
  },
  dropdownImage: {
    position: 'absolute',
    right: 10,
    bottom: 5,
    width: 20,
    height: 20
  },
  selectButton: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center',
    height: 40,
    margin: 5,
    borderRadius: 10,
  },
  operationBackNextButton: {
    color: 'white',
    fontSize: AppConst.styles.fontSizeM.fontSize,
  },
  ckeckBox: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  pesticideBox: {
    marginBottom: 5,
    textAlign: 'center'
  },
});

export default ScreenResult;

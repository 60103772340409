import React, { useState, useEffect } from 'react';
import { StyleSheet, Platform, ActivityIndicator, Text, Image, TouchableOpacity, View, SafeAreaView } from 'react-native';
import IconImages from './IconImages';
import * as CmnUtil from './CmnUtil';
import * as AppConst from './AppConst';
import * as AuthControl from './AuthControl';
import * as SettingData from './SettingData';

const ScreenMenu = ({ navigation }: { navigation: any }) => {
  // ログインアイコン
  const [loginIcon, setIcon] = useState(IconImages.icLogin);
  // ログイン状態
  const [loginState, setLoginState] = useState(false);
  // Wifiメッセージ表示
  const [wifiFlag, setWifiFlag] = useState(SettingData.getShowWifi() === 'on');
  // Wifiリマインドチェック
  const [wifiRemind, setWifiRemind] = useState(SettingData.getShowWifi() === 'off');
  useEffect(() => {
    SettingData.setShowWifi(wifiRemind ? 'off' : 'on', () => { }, () => { });
  }, [wifiRemind]);

  // ログイン／ログアウト
  const logInOut = async () => {
    if (loginState) {
      CmnUtil.startLoading();
      await AuthControl.logout();
      SettingData.setAutoLoginUser('', () => { }, () => { });
      CmnUtil.endLoading();
      setLoginState(AuthControl.getLoginState());
      CmnUtil.appMsg('ログアウトしました。');
    } else {
      navigation.navigate('Login', { next: 'back' });
    }
  }

  // ログイン状態により、アイコン変更
  useEffect(() => {
    if (loginState) {
      setIcon(IconImages.icLogout);
    } else {
      setIcon(IconImages.icLogin);
    }
  }, [loginState]);

  // 自動ログイン
  useEffect(() => {
    (async () => {
      // 各種メッセージ表示後、有効であれば自動ログイン
      if (!(wifiFlag || !SettingData.enableAutoLogin())) {
        CmnUtil.startLoading();
        const status = await AuthControl.login(SettingData.getLastUser(), SettingData.getLastPassword());
        if (status == AuthControl.AuthResult.Success) {
          setLoginState(AuthControl.getLoginState());
        }
        CmnUtil.endLoading();
      }
    })();
  }, [wifiFlag]);

  // メニュー画面に戻った場合の動き
  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      setLoginState(AuthControl.getLoginState());
    });
    return unsubscribe;
  }, [navigation]);

  // 撮影クリック
  const onClickCamera = () => {
    if (loginState) {
      navigation.navigate('Camera');
    } else {
      navigation.navigate('Login', { next: 'Camera' });
    }
  }

  // 診断クリック
  const onClickSianosis = () => {
    if (loginState) {
      navigation.navigate('Diaosis');
    } else {
      navigation.navigate('Login', { next: 'Diaosis' });
    }
  }

  // 診断履歴クリック
  const onClickHistory = () => {
    if (Platform.OS == 'web') {
      CmnUtil.appAlert('Web版では本機能はご利用になれません。');
    } else {
      navigation.navigate('History')
    }
  }

  // Wifiメッセージ
  const WifiView = () => {
    return (
      <View style={styles.wifi}>
        <View style={{ backgroundColor: 'white', maxWidth: 800, width: '90%', borderRadius: 5, padding: 20 }}>
          <Text style={AppConst.styles.fontSizeL}>このアプリは病害虫診断のために画像をアップロードします。</Text>
          <Text style={AppConst.styles.fontSizeL}>遅延、または通信量の追加を避けるため、Wi-Fiの使用をおすすめします。</Text>

          <TouchableOpacity
            style={{ flexDirection: 'row', marginTop: 30, alignContent: 'center' }}
            onPress={() => { setWifiRemind(!wifiRemind); }}
            activeOpacity={1.0}>
            <Image style={{ width: 25, height: 25 }} resizeMode='cover' source={wifiRemind ? IconImages.icCheckboxOn : IconImages.icCheckboxOff} />
            <Text style={Platform.OS === 'ios' ? { marginTop: 5 } : { marginTop: 2 }}>今後、起動時にこのダイアログを表示しない。</Text>
          </TouchableOpacity>

          <TouchableOpacity
            style={{ height: 40, borderRadius: 10, borderWidth: 1, justifyContent: 'center', alignContent: 'center', marginTop: 15 }}
            onPress={() => { setWifiFlag(false); }}>
            <Text style={{ textAlign: 'center' }}>OK</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.viewRow}>
        <TouchableOpacity style={[styles.menuButton, { backgroundColor: '#0d8f18', marginRight: 5 }]} onPress={onClickCamera}>
          <Image style={[styles.image, {}]} resizeMode='contain' source={IconImages.icCamera} />
        </TouchableOpacity>

        <TouchableOpacity style={[styles.menuButton, { backgroundColor: '#cc2f18', marginLeft: 5 }]} onPress={onClickSianosis}>
          <Image style={[styles.image, {}]} resizeMode='contain' source={IconImages.icDiagRequest} />
        </TouchableOpacity>
      </View>

      <View style={[styles.viewRow, { marginBottom: 10 }]}>
        <TouchableOpacity style={[styles.menuButton, { backgroundColor: '#601691', marginRight: 5 }]} onPress={onClickHistory}>
          <Image style={[styles.image, {}]} resizeMode='contain' source={IconImages.icDiagHistory} />
        </TouchableOpacity >

        <View style={{ flex: 1, marginLeft: 5 }}>
          <TouchableOpacity style={[styles.menuButton, { backgroundColor: '#3d61ff', marginBottom: 5 }]} onPress={logInOut}>
            <Image style={[styles.image, {}]} resizeMode='contain' source={loginIcon} />
          </TouchableOpacity>

          <TouchableOpacity style={[styles.menuButton, { backgroundColor: '#4c4c4c', marginTop: 5 }]} onPress={() => navigation.navigate('Setting')}>
            <Image style={[styles.image, {}]} resizeMode='contain' source={IconImages.icEtc} />
          </TouchableOpacity>
        </View>
      </View>

      {wifiFlag && <WifiView />}
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  viewRow: {
    flex: 1,
    flexDirection: 'row',
    marginTop: 10,
    marginHorizontal: 10,
  },
  menuButton: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center',
    borderRadius: 10,
  },
  image: {
    width: '100%',
    height: '100%',
    paddingVertical: 10,
    justifyContent: 'center',
    alignContent: 'center',
  },
  wifi: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
});
export default ScreenMenu;

import * as AppConst from './AppConst';
import * as AuthControl from './AuthControl';

// 型定義
export type Attributes = {
  category: string
  plant: string
  part?: string
}
export type Image = {
  filename: string
  data: string
  lat?: number
  lon?: number
  datetime: string
  answer?: string
}

// 診断API実行
export const dianosis = async (p_attributes: Attributes, p_image: Image, success: Function, fail: Function) => {
  // HTTPリクエスト
  fetch(AppConst.dianosisUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      token: AuthControl.getToken(),
      mock: "False",
    },
    body: JSON.stringify({
      assets: [
        {
          images: [
            p_image,
          ],
          attributes: convertCategory(p_attributes),
        }
      ],
    }),
  })
    .then(response => response.json())
    .then(responseJson => {
      //console.log(responseJson);
      success(responseJson);
    })
    .catch(error => {
      //console.error(error);
      fail();
    });
};

// 虫害->害虫変換
const convertCategory = (p_attributes: Attributes) => {
  let tmp: Attributes = {
    category: p_attributes.category == '虫害' ? '害虫' : p_attributes.category,
    plant: p_attributes.plant,
  };
  if (p_attributes.part) {
    tmp.part = p_attributes.part;
  }

  // 一発診断の場合はattributesを空に変更
  if (p_attributes.category == '対象選択なし') {
    tmp = {
      category: '',
      plant: '',
      part: '',
    };
  }
  return tmp;
}

// マスタ情報取得API実行
export const getMaster = async (success: Function, fail: Function) => {
  // HTTPリクエスト
  fetch(AppConst.getMasterUrl, {
    method: 'GET',
  })
    .then(response => response.json())
    .then(responseJson => {
      //console.log(responseJson);
      success(responseJson);
    })
    .catch(error => {
      //console.error(error);
      fail();
    });
};

import icCamera from './images/ic_camera.png';
import icDiagRequest from './images/ic_diag_request.png';
import icDiagHistory from './images/ic_diag_history.png';
import icLogin from './images/ic_login.png';
import icLogout from './images/ic_logout.png';
import icEtc from './images/ic_etc.png';
import icPictureSelect from './images/ic_picture_select.png';
import icPicture from './images/ic_picture.png';
import icSickLeaf from './images/ic_sick_leaf.png';
import icWorm from './images/ic_worm.png';
import icNone from './images/ic_none.png';
import icTomato from './images/ic_tomato.png';
import icCucumber from './images/ic_cucumber.png';
import icEggplant from './images/ic_eggplant.png';
import icStrawberry from './images/ic_strawberry.png';
import icImageInfoInvisible from './images/ic_imageinfo_invisible.png';
import icImageInfoVisible from './images/ic_imageinfo_visible.png';
import icClose from './images/ic_close.png';
import icDropdown from './images/ic_dropdown.png';
import icBack from './images/ic_back.png';
import icDelete from './images/ic_delete.png';
import icFillter from './images/ic_fillter.png';
import icSelected from './images/ic_selected.png';
import icCheckboxOn from './images/ic_checkbox_on.png';
import icCheckboxOff from './images/ic_checkbox_off.png';

export default class IconImages {
  // メニュー画像
  static readonly icCamera = icCamera;
  static readonly icDiagRequest = icDiagRequest;
  static readonly icDiagHistory = icDiagHistory;
  static readonly icLogin = icLogin;
  static readonly icLogout = icLogout;
  static readonly icEtc = icEtc;
  // 画像選択
  static readonly icPictureSelect = icPictureSelect;
  static readonly icPicture = icPicture;
  // 対象選択
  static readonly icSickLeaf = icSickLeaf;
  static readonly icWorm = icWorm;
  static readonly icNone = icNone;
  // 作物選択
  static readonly icTomato = icTomato;
  static readonly icCucumber = icCucumber;
  static readonly icEggplant = icEggplant;
  static readonly icStrawberry = icStrawberry;
  // ドロップダウン
  static readonly icDropdown = icDropdown;
  // 診断情報表示ボタン
  static readonly icImageInfoInvisible = icImageInfoInvisible;
  static readonly icImageInfoVisible = icImageInfoVisible;
  // 閉じるボタン
  static readonly icClose = icClose;
  // 戻るボタン
  static readonly icBack = icBack;
  // 削除ボタン
  static readonly icDelete = icDelete;
  // フィルタボタン
  static readonly icFillter = icFillter;
  // 選択アイコン
  static readonly icSelected = icSelected;
  // チェックボックス
  static readonly icCheckboxOn = icCheckboxOn;
  static readonly icCheckboxOff = icCheckboxOff;
}

import { StyleSheet } from 'react-native';

// 診断URL
export const dianosisUrl = 'https://l8b7kph9p9.execute-api.ap-northeast-1.amazonaws.com/dev/appsrvv3.0/api/diagnosis';

// マスタ情報取得URL
export const getMasterUrl = 'https://l8b7kph9p9.execute-api.ap-northeast-1.amazonaws.com/dev/appsrvv3.0/api/master';

// アンケートページ
export const questionnaireUrl = 'https://d6hlendu6cvh.cloudfront.net';

// Web版の診断時ファイル名
export const filenameWeb = 'webImage.jpg';

// 部位（トマト）
export let partTomato = [
  '葉（表）',
  '葉（裏）',
  '花',
  '果実',
  '茎',
  '茎頂部',
  '地際部',
  '根部',
  '全体',
];
// 部位（キュウリ）
export let partCucumber = [
  '葉（表）',
  '葉（裏）',
  '花',
  '果実',
  'つる',
  '茎',
  '茎頂部',
  '地際部',
  '全体',
];
// 部位（ナス）
export let partEggplant = [
  '葉（表）',
  '葉（裏）',
  '花',
  '果実',
  '茎',
  '茎頂部',
  '茎断面',
  '地際部',
  '全体',
];
// 部位（イチゴ）
export let partStrawberry = [
  '葉（表）',
  '葉（裏）',
  '葉柄',
  '花',
  '果実',
  '果柄',
  'ランナー',
  '茎頂部',
  'クラウン',
  '全体',
];

// 正解（病害,トマト）
export let answerDisaseTomato = [
  '健全',
  '葉かび病',
  '灰色かび病',
  'うどんこ病',
  '青枯病',
  'すすかび病',
  '褐色輪紋病',
  '黄化葉巻病',
  '褐色根腐病',
  'かいよう病',
  'モザイク病（ToMV)',
  '疫病',
];
// 正解（病害,キュウリ）
export let answerDisaseCucumber = [
  '健全',
  '灰色かび病',
  'うどんこ病',
  '疫病',
  '炭疽病',
  'CCYV',
  'CMV',
  'KGMMV',
  'MYSV',
  'ＷＭＶ',
  'ZYMV',
  'べと病',
  '褐斑病',
  'つる枯病',
  '菌核病',
  '黒星病',
  '斑点細菌病',
];
// 正解（病害,ナス）
export let answerDisaseEggplant = [
  '健全',
  '灰色かび病',
  'うどんこ病',
  '青枯病',
  'すすかび病',
  '輪紋病',
  '黒点根腐病',
  '根腐疫病',
  '褐斑病',
  '褐斑細菌病',
  '菌核病',
  '褐色斑点病',
  '褐色腐敗病',
  '褐色円星病',
  '褐紋病',
  '茎えそ細菌病',
  '茎腐細菌病',
  '黒枯病',
  '苗立枯病',
  '半枯病',
  '半身萎凋病',
];
// 正解（病害,イチゴ）
export let answerDisaseStrawberry = [
  '健全',
  '灰色かび病',
  'うどんこ病',
  '輪斑病',
  '疫病',
  '炭疽病（萎凋症）',
  '炭疽病（葉枯症）',
  '萎黄病',
];

// 正解（虫害,トマト）
export let answerPestTomato = [
  '健全',
  'ワタアブラムシ',
  'モモアカアブラムシ',
  'チューリップヒゲナガアブラムシ',
  'ジャガイモヒゲナガアブラムシ',
  'ヒラズハナアザミウマ',
  'ミカンキイロアザミウマ',
  'ネギアザミウマ',
  'タバココナジラミ',
  'オンシツコナジラミ',
  'トマトハモグリバエ',
  'マメハモグリバエ',
  'ナミハダニ',
  'カンザワハダニ',
  'オオタバコガ',
  'ハスモンヨトウ',
  'トマトサビダニ',
  'ネコブセンチュウ',
  'すす病（アブラムシ類）',
  'すす病（コナジラミ類）',
];
// 正解（虫害,キュウリ）
export let answerPestCucumber = [
  '健全',
  'ワタアブラムシ',
  'モモアカアブラムシ',
  'チューリップヒゲナガアブラムシ',
  'ジャガイモヒゲナガアブラムシ',
  'ミナミキイロアザミウマ',
  'ヒラズハナアザミウマ',
  'ミカンキイロアザミウマ',
  'ネギアザミウマ',
  'タバココナジラミ',
  'オンシツコナジラミ',
  'トマトハモグリバエ',
  'ナミハダニ',
  'カンザワハダニ',
  'オオタバコガ',
  'ハスモンヨトウ',
  'トマトサビダニ',
  'ネコブセンチュウ',
  'チャノホコリダニ',
  'ワタヘリクロノメイガ',
  'ウリキンウワバ',
  'ウリハムシ',
  'ヨトウガ',
  'ニジュウヤホシテントウ',
  'ノハラナメクジ',
  'すす病（アブラムシ類）',
  'すす病（コナジラミ類）',
];
// 正解（虫害,ナス）
export let answerPestEggplant = [
  '健全',
  'ワタアブラムシ',
  'モモアカアブラムシ',
  'チューリップヒゲナガアブラムシ',
  'ジャガイモヒゲナガアブラムシ',
  'ミナミキイロアザミウマ',
  'ヒラズハナアザミウマ',
  'ミカンキイロアザミウマ',
  'ネギアザミウマ',
  'タバココナジラミ',
  'オンシツコナジラミ',
  'トマトハモグリバエ',
  'マメハモグリバエ',
  'ナスハモグリバエ',
  'ナミハダニ',
  'カンザワハダニ',
  'オオタバコガ',
  'ハスモンヨトウ',
  'ネコブセンチュウ',
  'チャノホコリダニ',
  'アズキノメイガ',
  'タバコノミハムシ',
  'ナスノミハムシ',
  'カスミカメムシ類',
  'ウスカワマイマイ',
  'カブラヤガ',
  'アオクサカメムシ',
  'ミナミアオカメムシ',
  'メンガタスズメ',
  'ヨトウガ',
  'マメコガネ',
  'オオニジュウヤホシテントウ',
  'ニジュウヤホシテントウ',
  'チャコウラナメクジ',
  'すす病（アブラムシ類）',
  'すす病（コナジラミ類）',
];
// 正解（虫害,イチゴ）
export let answerPestStrawberry = [
  '健全',
  'ワタアブラムシ',
  'ヒラズハナアザミウマ',
  'ミカンキイロアザミウマ',
  'ネギアザミウマ',
  'チャノキイロアザミウマ',
  'タバココナジラミ',
  'オンシツコナジラミ',
  'ナミハダニ',
  'カンザワハダニ',
  'ハスモンヨトウ',
  'チャノホコリダニ',
  'すす病（アブラムシ類）',
  'すす病（コナジラミ類）',
];

export const setMasterInfo = (masterInfo: any) => {
  // 部位
  partTomato = masterInfo.part.tomato;
  partCucumber = masterInfo.part.cucumber;
  partEggplant = masterInfo.part.eggplant;
  partStrawberry = masterInfo.part.strawberry;

  // 正解（病害）
  answerDisaseTomato = masterInfo.answer.disease.tomato;
  answerDisaseCucumber = masterInfo.answer.disease.cucumber;
  answerDisaseEggplant = masterInfo.answer.disease.eggplant;
  answerDisaseStrawberry = masterInfo.answer.disease.strawberry;

  // 正解（虫害）
  answerPestTomato = masterInfo.answer.pest.tomato;
  answerPestCucumber = masterInfo.answer.pest.cucumber;
  answerPestEggplant = masterInfo.answer.pest.eggplant;
  answerPestStrawberry = masterInfo.answer.pest.strawberry;
}

// モード変更パスワード
export const changeModePasword = 'teacher';

// 診断履歴で1度に読み出す件数
export const historyPageLimit = 10;
// 診断履歴用のサムネサイズ
export const thumbnailWidth = 300;
// 診断履歴用のサムネ圧縮率
export const thumbnailCompress = 0.7;

// スタイル定義
export const defaultColor = '#3b5fff';
//export const defaultColor = '#1fa193';

export const pesticideBoxes = [
  {use: '殺菌剤', color: { box: '#00ac97', text: 'white' }},
  {use: '殺虫剤', color: { box: '#fdd35c', text: 'black' }},
  {use: '殺虫殺菌剤', color: { box: '#e3acae', text: 'black' }},
  {use: '農薬肥料', color: { box: '#5a4498', text: 'white' }},

  // その他は該当なしの場合に使用するので一番最後の要素として定義する
  {use: 'その他', color: { box: '#c9c9c2', text: 'black' }},
];

export const styles = StyleSheet.create({
  photo: {
    flex: 1,
  },
  fontSizeLL: {
    fontSize: 26,
  },
  fontSizeL: {
    fontSize: 20,
  },
  fontSizeM: {
    fontSize: 18,
  },
  fontSizeS: {
    fontSize: 12,
  },
  loading: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    backgroundColor: 'white',
    opacity: 0.6
  },
});

import * as SQLite from 'expo-sqlite';
import * as CmnUtil from './CmnUtil';

const dbName = 'aipest2';
const latestVer = '1.1';

// DB初期化
export const init = (callback: Function, fail: Function) => {
  const db = SQLite.openDatabase(dbName);
  db.transaction(tx => {
    tx.executeSql(
      'create table if not exists t_setting (name text primary key not null, value text);',
      undefined,
      commonSuccess,
      executeError
    );
    tx.executeSql(
      'create table if not exists t_diagnosis_result ('
      + 'result_id integer primary key autoincrement, '
      + 'filename text, '
      + 'data text, '
      + 'thumbnail text, '
      + 'lat real, '
      + 'lot real, '
      + 'answer text, '
      + 'category text, '
      + 'plant text, '
      + 'part text, '
      + 'photoW real, '
      + 'photoH real, '
      + 'photo_date text'
      + ');',
      undefined,
      commonSuccess,
      executeError
    );
    tx.executeSql(
      'create table if not exists t_diagnosis_group ('
      + 'result_id integer, '
      + 'group_id integer, '
      + 'point_lt_x integer, '
      + 'point_lt_y integer, '
      + 'point_rb_x integer, '
      + 'point_rb_y integer, '
      + 'primary key(result_id, group_id)'
      + ');',
      undefined,
      commonSuccess,
      executeError
    );
    tx.executeSql(
      'create table if not exists t_diagnosis_candidate ('
      + 'result_id integer, '
      + 'group_id integer, '
      + 'rank integer, '
      + 'probability real, '
      + 'estimated text, '
      + 'primary key(result_id, group_id, rank)'
      + ');',
      undefined,
      commonSuccess,
      executeError
    );
    tx.executeSql(
      'create table if not exists t_diagnosis_pesticide ('
      + 'result_id integer, '
      + 'group_id integer, '
      + 'rank integer, '
      + 'pesticide_rank integer, '
      + 'name text, '
      + 'company text, '
      + 'url text, '
      + 'primary key(result_id, group_id, rank, pesticide_rank)'
      + ');',
      undefined,
      commonSuccess,
      executeError
    );
    tx.executeSql(
      'create table if not exists t_diagnosis_photo ('
      + 'result_id integer, '
      + 'group_id integer, '
      + 'rank integer, '
      + 'photo_rank integer, '
      + 'thumbnail text, '
      + 'original text, '
      + 'primary key(result_id, group_id, rank, photo_rank)'
      + ');',
      undefined,
      commonSuccess,
      executeError
    );
    tx.executeSql(
      'create table if not exists t_active_ingredient ('
      + 'result_id integer, '
      + 'group_id integer, '
      + 'rank integer, '
      + 'pesticide_rank integer, '
      + 'active_ingredient_rank integer, '
      + 'active_ingredient text, '
      + 'primary key(result_id, group_id, rank, pesticide_rank, active_ingredient_rank)'
      + ');',
      undefined,
      commonSuccess,
      executeError
    );
    tx.executeSql(
      'create table if not exists t_irac_code ('
      + 'result_id integer, '
      + 'group_id integer, '
      + 'rank integer, '
      + 'pesticide_rank integer, '
      + 'irac_code_rank integer, '
      + 'irac_code text, '
      + 'primary key(result_id, group_id, rank, pesticide_rank, irac_code_rank)'
      + ');',
      undefined,
      commonSuccess,
      executeError
    );
    tx.executeSql(
      'create table if not exists t_frac_code ('
      + 'result_id integer, '
      + 'group_id integer, '
      + 'rank integer, '
      + 'pesticide_rank integer, '
      + 'frac_code_rank integer, '
      + 'frac_code text, '
      + 'primary key(result_id, group_id, rank, pesticide_rank, frac_code_rank)'
      + ');',
      undefined,
      commonSuccess,
      executeError
    );
    // DBアップデート処理
    tx.executeSql(
      'select value from t_setting where name = ?;',
      ['dbVersion'],
      (_, resultSet) => {
        // 初版からのアップデート
        if (resultSet.rows.length == 0) {
          tx.executeSql(
            'alter table t_diagnosis_pesticide add column use text;',
            undefined,
            commonSuccess,
            executeError
          );
          tx.executeSql(
            'alter table t_diagnosis_pesticide add column dosage_form text;',
            undefined,
            commonSuccess,
            executeError
          );
          tx.executeSql(
            'alter table t_diagnosis_pesticide add column register_date text;',
            undefined,
            commonSuccess,
            executeError
          );
        }
      },
      executeError
    );
    // DBバージョン更新
    tx.executeSql(
      'insert or replace into t_setting (name, value) values (?, ?);',
      ['dbVersion', latestVer],
      commonSuccess,
      executeError
    );
  },
    (error: SQLite.SQLError) => {
      transactionError(error);
      fail();
    },
    () => {
      callback();
    },
  );
}

// 設定値読出
export const getSetting = (callback: Function, callback2: Function, fail: Function) => {
  const db = SQLite.openDatabase(dbName);
  db.transaction(tx => {
    tx.executeSql(
      'select name, value from t_setting',
      undefined,
      (_, resultSet) => {
        // 取得値を1レコードずつコールバック
        for (let i = 0; i < resultSet.rows.length; i++) {
          callback(resultSet.rows.item(i).name, resultSet.rows.item(i).value);
        }
      },
      executeError
    );
  },
    (error: SQLite.SQLError) => {
      transactionError(error);
      fail();
    },
    () => { callback2(); }
  );
}

// 設定値設定
export const setSetting = (name: string, value: string, callback: Function, fail: Function) => {
  const db = SQLite.openDatabase(dbName);
  db.transaction(tx => {
    tx.executeSql(
      'insert or replace into t_setting (name, value) values (?, ?);',
      [name, value],
      commonSuccess,
      executeError
    );
  },
    (error: SQLite.SQLError) => {
      transactionError(error);
      fail();
    },
    () => {
      callback();
    },
  );
}

// 診断結果一覧読出
export const getDianosisResultList = (page: number, pageLimit: number, fillter: any, callback: Function, fail: Function) => {
  const offset = pageLimit * (page - 1);
  const db = SQLite.openDatabase(dbName);
  db.transaction(tx => {
    // フィルタ条件作成
    const where = ['where 1 = 1 '];
    const data: any[] = [];
    if (fillter.category !== '指定なし') {
      where.push('AND R.category = ? ');
      data.push(fillter.category);
    }
    if (fillter.plant !== '指定なし') {
      where.push('AND R.plant = ? ');
      data.push(fillter.plant);
    }
    if (fillter.from !== '指定なし') {
      where.push('AND ? < R.photo_date ');
      data.push(fillter.from + ' 00:00:00');
    }
    if (fillter.to !== '指定なし') {
      where.push('AND R.photo_date < ? ');
      data.push(fillter.to + ' 23:59:59');
    }
    if (fillter.estimated !== '指定なし') {
      where.push('AND C.estimated = ? ');
      data.push(fillter.estimated);
    }
    data.push(pageLimit);
    data.push(offset);

    tx.executeSql(
      'select R.result_id, R.thumbnail, R.photo_date, R.category, R.plant, R.part, R.answer, C.probability, C.estimated '
      + 'from t_diagnosis_result R '
      + 'left join t_diagnosis_candidate C '
      + 'on R.result_id = C.result_id and C.group_id = 0 and C.rank = 0 '
      + where.join('')
      + 'order by R.result_id desc '
      + 'limit ? offset ?;',
      data,
      (_, resultSet) => {
        const results = [];
        for (let i = 0; i < resultSet.rows.length; i++) {
          results.push({
            result_id: resultSet.rows.item(i).result_id,
            thumbnail: resultSet.rows.item(i).thumbnail,
            photo_date: resultSet.rows.item(i).photo_date,
            category: resultSet.rows.item(i).category,
            plant: resultSet.rows.item(i).plant,
            part: resultSet.rows.item(i).part,
            answer: resultSet.rows.item(i).answer,
            probability: resultSet.rows.item(i).probability,
            estimated: resultSet.rows.item(i).estimated,
          });
        }
        callback(results);
      },
      executeError
    );
  },
    (error: SQLite.SQLError) => {
      transactionError(error);
      fail();
    },
    commonSuccess
  );
}

// 診断結果読出
export const getDianosisResult = (id: number, callback: Function, fail: Function) => {
  const db = SQLite.openDatabase(dbName);
  const result: { result_id: any; filename: any; data: any; lat: any; lot: any; answer: any; category: any; plant: any; part: any; photoW: any; photoH: any; photo_date: any; }[] = [];
  const group: { result_id: any; group_id: any; point_lt_x: any; point_lt_y: any; point_rb_x: any; point_rb_y: any; }[] = [];
  const candidate: { result_id: any; group_id: any; rank: any; probability: any; estimated: any; }[] = [];
  const pesticide: { result_id: any; group_id: any; rank: any; pesticide_rank: any; name: any; company: any; url: any; use: any; dosage_form: any; register_date: any; }[] = [];
  const photo: { result_id: any; group_id: any; rank: any; photo_rank: any; thumbnail: any; original: any; }[] = [];
  const active_ingredient: { result_id: any; group_id: any; rank: any; pesticide_rank: any; active_ingredient_rank: any; active_ingredient: any; }[] = [];
  const irac_code: { result_id: any; group_id: any; rank: any; pesticide_rank: any; irac_code_rank: any; irac_code: any; }[] = [];
  const frac_code: { result_id: any; group_id: any; rank: any; pesticide_rank: any; frac_code_rank: any; frac_code: any; }[] = [];

  db.transaction(tx => {
    tx.executeSql(
      'select result_id, filename, data, lat, lot, answer, category, plant, part, photoW, photoH, photo_date '
      + 'from t_diagnosis_result '
      + 'where result_id = ?;',
      [id],
      (_, resultSet) => {
        for (let i = 0; i < resultSet.rows.length; i++) {
          result.push({
            result_id: resultSet.rows.item(i).result_id,
            filename: resultSet.rows.item(i).filename,
            data: resultSet.rows.item(i).data,
            lat: resultSet.rows.item(i).lat,
            lot: resultSet.rows.item(i).lot,
            answer: resultSet.rows.item(i).answer,
            category: resultSet.rows.item(i).category,
            plant: resultSet.rows.item(i).plant,
            part: resultSet.rows.item(i).part,
            photoW: resultSet.rows.item(i).photoW,
            photoH: resultSet.rows.item(i).photoH,
            photo_date: resultSet.rows.item(i).photo_date,
          });
        }
      },
      executeError
    );
    tx.executeSql(
      'select result_id, group_id, point_lt_x, point_lt_y, point_rb_x, point_rb_y '
      + 'from t_diagnosis_group '
      + 'where result_id = ? '
      + 'order by result_id, group_id;',
      [id],
      (_, resultSet) => {
        for (let i = 0; i < resultSet.rows.length; i++) {
          group.push({
            result_id: resultSet.rows.item(i).result_id,
            group_id: resultSet.rows.item(i).group_id,
            point_lt_x: resultSet.rows.item(i).point_lt_x,
            point_lt_y: resultSet.rows.item(i).point_lt_y,
            point_rb_x: resultSet.rows.item(i).point_rb_x,
            point_rb_y: resultSet.rows.item(i).point_rb_y,
          });
        }
      },
      executeError
    );
    tx.executeSql(
      'select result_id, group_id, rank, probability, estimated '
      + 'from t_diagnosis_candidate '
      + 'where result_id = ? '
      + 'order by result_id, group_id, rank;',
      [id],
      (_, resultSet) => {
        for (let i = 0; i < resultSet.rows.length; i++) {
          candidate.push({
            result_id: resultSet.rows.item(i).result_id,
            group_id: resultSet.rows.item(i).group_id,
            rank: resultSet.rows.item(i).rank,
            probability: resultSet.rows.item(i).probability,
            estimated: resultSet.rows.item(i).estimated,
          });
        }
      },
      executeError
    );
    tx.executeSql(
      'select result_id, group_id, rank, pesticide_rank, name, company, url, use, dosage_form, register_date '
      + 'from t_diagnosis_pesticide '
      + 'where result_id = ? '
      + 'order by result_id, group_id, rank, pesticide_rank;',
      [id],
      (_, resultSet) => {
        for (let i = 0; i < resultSet.rows.length; i++) {
          pesticide.push({
            result_id: resultSet.rows.item(i).result_id,
            group_id: resultSet.rows.item(i).group_id,
            rank: resultSet.rows.item(i).rank,
            pesticide_rank: resultSet.rows.item(i).pesticide_rank,
            name: resultSet.rows.item(i).name,
            company: resultSet.rows.item(i).company,
            url: resultSet.rows.item(i).url,
            use: resultSet.rows.item(i).use,
            dosage_form: resultSet.rows.item(i).dosage_form,
            register_date: resultSet.rows.item(i).register_date,
          });
        }
      },
      executeError
    );
    tx.executeSql(
      'select result_id, group_id, rank, photo_rank, thumbnail, original '
      + 'from t_diagnosis_photo '
      + 'where result_id = ? '
      + 'order by result_id, group_id, rank, photo_rank;',
      [id],
      (_, resultSet) => {
        for (let i = 0; i < resultSet.rows.length; i++) {
          photo.push({
            result_id: resultSet.rows.item(i).result_id,
            group_id: resultSet.rows.item(i).group_id,
            rank: resultSet.rows.item(i).rank,
            photo_rank: resultSet.rows.item(i).photo_rank,
            thumbnail: resultSet.rows.item(i).thumbnail,
            original: resultSet.rows.item(i).original,
          });
        }
      },
      executeError
    );
    tx.executeSql(
      'select result_id, group_id, rank, pesticide_rank, active_ingredient_rank, active_ingredient '
      + 'from t_active_ingredient '
      + 'where result_id = ? '
      + 'order by result_id, group_id, rank, pesticide_rank, active_ingredient_rank;',
      [id],
      (_, resultSet) => {
        for (let i = 0; i < resultSet.rows.length; i++) {
          active_ingredient.push({
            result_id: resultSet.rows.item(i).result_id,
            group_id: resultSet.rows.item(i).group_id,
            rank: resultSet.rows.item(i).rank,
            pesticide_rank: resultSet.rows.item(i).pesticide_rank,
            active_ingredient_rank: resultSet.rows.item(i).active_ingredient_rank,
            active_ingredient: resultSet.rows.item(i).active_ingredient,
          });
        }
      },
      executeError
    );
    tx.executeSql(
      'select result_id, group_id, rank, pesticide_rank, irac_code_rank, irac_code '
      + 'from t_irac_code '
      + 'where result_id = ? '
      + 'order by result_id, group_id, rank, pesticide_rank, irac_code_rank;',
      [id],
      (_, resultSet) => {
        for (let i = 0; i < resultSet.rows.length; i++) {
          irac_code.push({
            result_id: resultSet.rows.item(i).result_id,
            group_id: resultSet.rows.item(i).group_id,
            rank: resultSet.rows.item(i).rank,
            pesticide_rank: resultSet.rows.item(i).pesticide_rank,
            irac_code_rank: resultSet.rows.item(i).irac_code_rank,
            irac_code: resultSet.rows.item(i).irac_code,
          });
        }
      },
      executeError
    );
    tx.executeSql(
      'select result_id, group_id, rank, pesticide_rank, frac_code_rank, frac_code '
      + 'from t_frac_code '
      + 'where result_id = ? '
      + 'order by result_id, group_id, rank, pesticide_rank, frac_code_rank;',
      [id],
      (_, resultSet) => {
        for (let i = 0; i < resultSet.rows.length; i++) {
          frac_code.push({
            result_id: resultSet.rows.item(i).result_id,
            group_id: resultSet.rows.item(i).group_id,
            rank: resultSet.rows.item(i).rank,
            pesticide_rank: resultSet.rows.item(i).pesticide_rank,
            frac_code_rank: resultSet.rows.item(i).frac_code_rank,
            frac_code: resultSet.rows.item(i).frac_code,
          });
        }
      },
      executeError
    );
  },
    (error: SQLite.SQLError) => {
      transactionError(error);
      fail();
    },
    () => {
      callback(result, group, candidate, pesticide, photo, active_ingredient, irac_code, frac_code);
    }
  );
}

// 診断結果設定
export const setDianosisResult = (info: any, json: any, callback: Function, fail: Function) => {
  const db = SQLite.openDatabase(dbName);

  // 診断結果
  db.transaction(tx => {
    tx.executeSql(
      'insert into t_diagnosis_result (filename, data, thumbnail, lat, lot, answer, category, plant, part, photoW, photoH, photo_date)'
      + ' values (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?);',
      [info.filename, info.data, info.thumbnail, info.lat, info.lot, info.answer, info.category, info.plant, info.part, info.photoW, info.photoH, info.photo_date],
      (_, resultSet) => {
        for (let i = 0; i < json.assets[0].images[0].results.length; i++) {
          const result = json.assets[0].images[0].results[i];

          // bboxはundefinedの場合がある
          let bbox = result.bbox != undefined ? result.bbox : [undefined, undefined, undefined, undefined];

          // 診断グループ
          tx.executeSql(
            'insert into t_diagnosis_group (result_id, group_id, point_lt_x, point_lt_y, point_rb_x, point_rb_y)'
            + ' values (?, ?, ?, ?, ?, ?);',
            [resultSet.insertId, i, bbox[0], bbox[1], bbox[2], bbox[3]],
            commonSuccess,
            executeError
          );

          // アプリ上では上位５件のみ保持
          result.candidates = result.candidates.slice(0, 5)

          for (let j = 0; j < result.candidates.length; j++) {
            const candidate = result.candidates[j];

            // 診断結果詳細
            tx.executeSql(
              'insert into t_diagnosis_candidate (result_id, group_id, rank, probability, estimated)'
              + ' values (?, ?, ?, ?, ?);',
              [resultSet.insertId, i, j, candidate.probability, candidate.estimated],
              commonSuccess,
              executeError
            );

            for (let k = 0; k < candidate.pesticide.length; k++) {
              const pesticide = candidate.pesticide[k];

              // 農薬情報
              tx.executeSql(
                'insert into t_diagnosis_pesticide (result_id, group_id, rank, pesticide_rank, name, company, url, use, dosage_form, register_date)'
                + ' values (?, ?, ?, ?, ?, ?, ?, ?, ?, ?);',
                [resultSet.insertId, i, j, k, pesticide.name, pesticide.company, pesticide.url, pesticide.use, pesticide.dosage_form, pesticide.register_date],
                commonSuccess,
                executeError
              );

              if (pesticide.active_ingredient != null) {
                for (let m = 0; m < pesticide.active_ingredient.length; m++) {
                  const active_ingredient = pesticide.active_ingredient[m];

                  // 有効成分情報
                  tx.executeSql(
                    'insert into t_active_ingredient (result_id, group_id, rank, pesticide_rank, active_ingredient_rank, active_ingredient)'
                    + ' values (?, ?, ?, ?, ?, ?);',
                    [resultSet.insertId, i, j, k, m, active_ingredient],
                    commonSuccess,
                    executeError
                  );
                }
              }

              if (pesticide.irac_code != null) {
                for (let m = 0; m < pesticide.irac_code.length; m++) {
                  const irac_code = pesticide.irac_code[m];

                  // 有効成分情報
                  tx.executeSql(
                    'insert into t_irac_code (result_id, group_id, rank, pesticide_rank, irac_code_rank, irac_code)'
                    + ' values (?, ?, ?, ?, ?, ?);',
                    [resultSet.insertId, i, j, k, m, irac_code],
                    commonSuccess,
                    executeError
                  );
                }
              }

              if (pesticide.frac_code != null) {
                for (let m = 0; m < pesticide.frac_code.length; m++) {
                  const frac_code = pesticide.frac_code[m];

                  // 有効成分情報
                  tx.executeSql(
                    'insert into t_frac_code (result_id, group_id, rank, pesticide_rank, frac_code_rank, frac_code)'
                    + ' values (?, ?, ?, ?, ?, ?);',
                    [resultSet.insertId, i, j, k, m, frac_code],
                    commonSuccess,
                    executeError
                  );
                }
              }
            }

            for (let k = 0; k < candidate.photos.length; k++) {
              const photo = candidate.photos[k];

              // 参考画像情報
              tx.executeSql(
                'insert into t_diagnosis_photo (result_id, group_id, rank, photo_rank, thumbnail, original)'
                + ' values (?, ?, ?, ?, ?, ?);',
                [resultSet.insertId, i, j, k, photo.thumbnail, photo.original],
                commonSuccess,
                executeError
              );
            }
          }
        }
      },
      executeError
    );
  },
    (error: SQLite.SQLError) => {
      transactionError(error);
      fail();
    },
    () => {
      callback();
    },
  );
}

// 診断結果削除
export const deleteDianosisResult = (idList: number[], callback: Function, fail: Function) => {
  const db = SQLite.openDatabase(dbName);

  db.transaction(tx => {
    for (let index = 0; index < idList.length; index++) {
      const id = idList[index];

      tx.executeSql(
        'select data from t_diagnosis_result where result_id = ?;',
        [id],
        (_, resultSet) => {
          // 画像ファイル削除
          for (let i = 0; i < resultSet.rows.length; i++) {
            CmnUtil.deleteFile(resultSet.rows.item(i).data);
          }

          // 診断結果
          tx.executeSql(
            'delete from t_diagnosis_result where result_id = ? ',
            [id],
            commonSuccess,
            executeError
          );

          // 診断グループ
          tx.executeSql(
            'delete from t_diagnosis_group where result_id = ? ',
            [id],
            commonSuccess,
            executeError
          );

          // 診断結果詳細
          tx.executeSql(
            'delete from t_diagnosis_candidate where result_id = ? ',
            [id],
            commonSuccess,
            executeError
          );

          // 農薬情報
          tx.executeSql(
            'delete from t_diagnosis_pesticide where result_id = ? ',
            [id],
            commonSuccess,
            executeError
          );

          // 参考画像情報
          tx.executeSql(
            'delete from t_diagnosis_photo where result_id = ? ',
            [id],
            commonSuccess,
            executeError
          );

          // 有効成分情報
          tx.executeSql(
            'delete from t_active_ingredient where result_id = ? ',
            [id],
            commonSuccess,
            executeError
          );

          // IRACコード情報
          tx.executeSql(
            'delete from t_irac_code where result_id = ? ',
            [id],
            commonSuccess,
            executeError
          );

          // FRACコード情報
          tx.executeSql(
            'delete from t_frac_code where result_id = ? ',
            [id],
            commonSuccess,
            executeError
          );
        },
        executeError
      );
    }
  },
    (error: SQLite.SQLError) => {
      transactionError(error);
      fail();
    },
    () => {
      callback();
    },
  );
}

// executeSql失敗時のコールバック関数
const executeError = (transaction: SQLite.SQLTransaction, error: SQLite.SQLError) => {
  console.log('executeError');
  console.log(transaction);
  console.log(error);
  return true;
}

// transaction失敗時のコールバック関数
const transactionError = (error: SQLite.SQLError) => {
  CmnUtil.appAlert('システム障害です。時間をおいて、再度お試しください。');
  console.log('transactionError');
  console.log(error);
}

// 正常時のコールバック関数
const commonSuccess = () => {
}

import Constants from 'expo-constants';
import Amplify, { Auth } from 'aws-amplify';

Amplify.configure(Constants.manifest!.extra!.awsconfig);

// ログイン結果
export enum AuthResult {
  Success,
  UserMismatch,
  PasswordMismatch,
  CodeMismatch,
  InvalidParameter,
  Other,
}

// ログイン状態
let loginState: boolean = false;
export const getLoginState = () => {
  return loginState;
}

// ログイン
export const login = async (user: string, password: string) => {
  try {
    await Auth.signIn(user, password);
  } catch (err: any) {
    switch (err.code) {
      case 'UserNotFoundException':
        return AuthResult.UserMismatch;
      case 'NotAuthorizedException':
        return AuthResult.PasswordMismatch;
      default:
        return AuthResult.Other;
    }
  }
  loginState = true;
  return AuthResult.Success;
};

// token取得
let idToken: string = '';
export const refreshToken = async () => {
  try {
    const session = await Auth.currentSession();
    idToken = session.getIdToken().getJwtToken();
  } catch (err) {
    console.log(err);
    return false;
  }
  return true;
};
export const getToken = () => {
  return idToken;
}

// ログアウト
export const logout = async () => {
  try {
    await Auth.signOut();
  } catch (err) {
  }
  loginState = false;
};

// パスワード忘れ
export const forgotPassword = async (user: string, success: Function, fail: Function) => {
  await Auth.forgotPassword(user)
    .then(data => {
      success();
    })
    .catch(err => {
      let authResult = AuthResult.Success;
      switch (err.code) {
        case 'UserNotFoundException':
          authResult = AuthResult.UserMismatch;
          break;
        default:
          authResult = AuthResult.Other;
          break;
      }
      fail(authResult);
    });
};

// パスワード変更
export const forgotPasswordSubmit = async (user: string, confirmCode: string, newPassword: string, success: Function, fail: Function) => {
  await Auth.forgotPasswordSubmit(user, confirmCode, newPassword)
    .then(data => {
      success();
    })
    .catch(err => {
      console.log(err);
      let authResult = AuthResult.Success;
      switch (err.code) {
        case 'UserNotFoundException':
          authResult = AuthResult.UserMismatch;
          break;
        case 'CodeMismatchException':
          authResult = AuthResult.CodeMismatch;
          break;
        case 'InvalidParameterException':
          authResult = AuthResult.InvalidParameter;
          break;
        default:
          authResult = AuthResult.Other;
          break;
      }
      fail(authResult);
    });
};

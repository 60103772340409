import { Platform } from 'react-native';
import * as DbControl from './DbControl';

// モード
let mode = 'normal';
export const getMode = () => {
  return mode;
}
export const setMode = (p_value: string, callback: Function, fail: Function) => {
  mode = p_value;
  if (Platform.OS === 'web') {
    localStorage.setItem('mode', p_value);
    callback();
  } else {
    DbControl.setSetting('mode', p_value, callback, fail);
  }
}

// 一発診断
let oneShot = 'normal';
export const getOneShot = () => {
  return oneShot;
}
export const setOneShot = (p_value: string, callback: Function, fail: Function) => {
  oneShot = p_value;
  if (Platform.OS === 'web') {
    localStorage.setItem('oneShot', p_value);
    callback();
  } else {
    DbControl.setSetting('oneShot', p_value, callback, fail);
  }
}

// 前回選択対象
let lastCategory = '';
export const getLastCategory = () => {
  return lastCategory;
}
export const setLastCategory = (p_value: string, callback: Function, fail: Function) => {
  lastCategory = p_value;
  if (Platform.OS === 'web') {
    localStorage.setItem('lastCategory', p_value);
    callback();
  } else {
    DbControl.setSetting('lastCategory', p_value, callback, fail);
  }
}

// 前回選択作物
let lastPlant = '';
export const getLastPlant = () => {
  return lastPlant;
}
export const setLastPlant = (p_value: string, callback: Function, fail: Function) => {
  lastPlant = p_value;
  if (Platform.OS === 'web') {
    localStorage.setItem('lastPlant', p_value);
    callback();
  } else {
    DbControl.setSetting('lastPlant', p_value, callback, fail);
  }
}

// 前回選択部位
let lastPart = '部位未選択';
export const getLastPart = () => {
  return lastPart;
}
export const setLastPart = (p_value: string, callback: Function, fail: Function) => {
  lastPart = p_value;
  if (Platform.OS === 'web') {
    localStorage.setItem('lastPart', p_value);
    callback();
  } else {
    DbControl.setSetting('lastPart', p_value, callback, fail);
  }
}

// 前回選択正解
let lastAnswer = '正解未選択';
export const getLastAnswer = () => {
  return lastAnswer;
}
export const setLastAnswer = (p_value: string, callback: Function, fail: Function) => {
  lastAnswer = p_value;
  if (Platform.OS === 'web') {
    localStorage.setItem('lastAnswer', p_value);
    callback();
  } else {
    DbControl.setSetting('lastAnswer', p_value, callback, fail);
  }
}

// wifiお知らせ表示
let showWifi = 'on';
export const getShowWifi = () => {
  return showWifi;
}
export const setShowWifi = (p_value: string, callback: Function, fail: Function) => {
  showWifi = p_value;
  if (Platform.OS === 'web') {
    localStorage.setItem('showWifi', p_value);
    callback();
  } else {
    DbControl.setSetting('showWifi', p_value, callback, fail);
  }
}

// 前回ログインユーザー名
let lastUser = '';
export const getLastUser = () => {
  return lastUser;
}
export const setLastUser = (p_value: string, callback: Function, fail: Function) => {
  lastUser = p_value;
  if (Platform.OS === 'web') {
    localStorage.setItem('lastUser', p_value);
    callback();
  } else {
    DbControl.setSetting('lastUser', p_value, callback, fail);
  }
}

// 前回ログインパスワード
let lastPassword = '';
export const getLastPassword = () => {
  return lastPassword;
}
export const setLastPassword = (p_value: string, callback: Function, fail: Function) => {
  lastPassword = p_value;
  if (Platform.OS === 'web') {
    // パスワードはlocalStorageに保存しない
    //localStorage.setItem('lastPassword', p_value);
    callback();
  } else {
    DbControl.setSetting('lastPassword', p_value, callback, fail);
  }
}

// 自動ログインユーザ
let autoLoginUser = '';
export const getAutoLoginUser = () => {
  return autoLoginUser;
}
export const setAutoLoginUser = (p_value: string, callback: Function, fail: Function) => {
  autoLoginUser = p_value;
  if (Platform.OS === 'web') {
    // パスワードはlocalStorageに保存しないため自動ログインさせない
    //localStorage.setItem('autoLoginUser', p_value);
    callback();
  } else {
    DbControl.setSetting('autoLoginUser', p_value, callback, fail);
  }
}
export const enableAutoLogin = () => {
  return autoLoginUser != '';
}

// 設定値ロード
export const loadSetting = (callback: Function, fail: Function) => {
  if (Platform.OS === 'web') {
    if (localStorage.getItem('mode') != undefined) {
      mode = localStorage.getItem('mode')!;
    }
    if (localStorage.getItem('oneShot') != undefined) {
      oneShot = localStorage.getItem('oneShot')!;
    }
    if (localStorage.getItem('lastCategory') != undefined) {
      lastCategory = localStorage.getItem('lastCategory')!;
    }
    if (localStorage.getItem('lastPlant') != undefined) {
      lastPlant = localStorage.getItem('lastPlant')!;
    }
    if (localStorage.getItem('lastPart') != undefined) {
      lastPart = localStorage.getItem('lastPart')!;
    }
    if (localStorage.getItem('lastAnswer') != undefined) {
      lastAnswer = localStorage.getItem('lastAnswer')!;
    }
    if (localStorage.getItem('showWifi') != undefined) {
      showWifi = localStorage.getItem('showWifi')!;
    }
    if (localStorage.getItem('lastUser') != undefined) {
      lastUser = localStorage.getItem('lastUser')!;
    }
    if (localStorage.getItem('lastPassword') != undefined) {
      lastPassword = localStorage.getItem('lastPassword')!;
    }
    if (localStorage.getItem('autoLoginUser') != undefined) {
      autoLoginUser = localStorage.getItem('autoLoginUser')!;
    }
    callback();
  } else {
    DbControl.getSetting((name: string, value: string) => {
      // DBの値で設定値を上書き
      switch (name) {
        case 'mode':
          mode = value;
          break;
        case 'oneShot':
          oneShot = value;
          break;
        case 'lastCategory':
          lastCategory = value;
          break;
        case 'lastPlant':
          lastPlant = value;
          break;
        case 'lastPart':
          lastPart = value;
          break;
        case 'lastAnswer':
          lastAnswer = value;
          break;
        case 'showWifi':
          showWifi = value;
          break;
        case 'lastUser':
          lastUser = value;
          break;
        case 'lastPassword':
          lastPassword = value;
          break;
        case 'autoLoginUser':
          autoLoginUser = value;
          break;
        default:
          break;
      }
    }, callback, fail);
  }
}

// 一発診断モードか返却する
export const isOneShot = () => {
  if (getOneShot() === 'oneShot') {
    return true;
  } else {
    return false;
  }
}

import React, { useState, useEffect } from 'react';
import { View, Platform, Image, Alert, BackHandler, ActivityIndicator } from 'react-native';
import { StatusBar } from "react-native";
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import * as AppConst from './src/AppConst';
import IconImages from './src/IconImages';
import ScreenMenu from './src/ScreenMenu';
import ScreenSetting from './src/ScreenSetting';
import ScreenTeacher from './src/ScreenTeacher';
import ScreenLicense from './src/ScreenLicense';
import ScreenLogin from './src/ScreenLogin';
import ScreenResetPassword from './src/ScreenResetPassword';
import ScreenCamera from './src/ScreenCamera';
import ScreenDianosis from './src/ScreenDianosis';
import ScreenResult from './src/ScreenResult';
import ScreenHistory from './src/ScreenHistory';
import * as SettingData from './src/SettingData';
import * as DbControl from './src/DbControl';
import * as ImagePicker from 'expo-image-picker';
import * as Location from 'expo-location';
import * as CmnUtil from './src/CmnUtil';
import * as ApiControl from './src/ApiControl';

// 画面遷移作成
const MainStack = createStackNavigator();

export default function App() {
  // 準備中
  const [ready, setReady] = useState(false);

  // 処理中
  const [loading, setLoading] = useState(false);
  CmnUtil.setLoadingFunc(setLoading);

  // 権限確認、設定値ロード
  useEffect(() => {
    (async () => {
      // 権限確認
      if (Platform.OS !== 'web') {
        let result = await ImagePicker.requestCameraPermissionsAsync();
        if (result.status !== 'granted') {
          Alert.alert('権限の無効', '権限が無効になっているため、アプリを起動できませんでした。設定から権限を有効にしてください。',
            [{ text: 'OK', onPress: () => BackHandler.exitApp() }]);
          return;
        }
        result = await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (result.status !== 'granted') {
          Alert.alert('権限の無効', '権限が無効になっているため、アプリを起動できませんでした。設定から権限を有効にしてください。',
            [{ text: 'OK', onPress: () => BackHandler.exitApp() }]);
          return;
        }
        result = await Location.requestForegroundPermissionsAsync();

        // DB初期化
        DbControl.init(() => {
          // アプリ初期化
          initApplication();
        },
          () => { }
        );
      } else {
        // アプリ初期化
        initApplication();
      }
    })();
  }, []);

  // アプリ初期化処理
  const initApplication = () => {
    // 設定値ロード
    SettingData.loadSetting(() => {
      ApiControl.getMaster((response: any) => {
        if (response.statusCode == 0) {
          AppConst.setMasterInfo(response.body);
        } else {
          CmnUtil.appAlert("マスター情報の取得に失敗しました。");
        }
        setReady(true);
      }, () => {
        CmnUtil.appAlert("マスター情報の取得に失敗しました。");
        setReady(true);
      });
    },
      () => { }
    );
  }

  // 準備中
  if (!ready) {
    return (<></>);
  }

  return (
    <>
      {/* 画面遷移 */}
      <NavigationContainer>
        <StatusBar barStyle={'default'} />
        <MainStack.Navigator
          headerMode='float'
          screenOptions={{
            headerStyle: {
              backgroundColor: AppConst.defaultColor,
            },
            headerTintColor: 'white',
            headerTitleStyle: {
              alignContent: 'center',
            },
            headerTitleAlign: 'center',
            headerBackTitleVisible: false,
            headerBackImage: () => (
              <View>
                <Image style={{ width: 30, height: 30, marginLeft: 10 }} resizeMode='contain' source={IconImages.icBack} />
              </View>
            ),
            gestureEnabled: false,
          }}>
          <MainStack.Screen name="Home" component={ScreenMenu} options={{ title: 'AI病害虫診断' }} />
          <MainStack.Screen name="Setting" component={ScreenSetting} options={{ title: 'その他' }} />
          <MainStack.Screen name="Teacher" component={ScreenTeacher} options={{ title: 'その他' }} />
          <MainStack.Screen name="License" component={ScreenLicense} options={{ title: 'ライセンス表示' }} />
          <MainStack.Screen name="Camera" component={ScreenCamera} options={{ title: '画像を撮影する' }} />
          <MainStack.Screen name="Diaosis" component={ScreenDianosis} options={{ title: '画像を診断する' }} />
          <MainStack.Screen name="Result" component={ScreenResult} options={{ title: '診断結果' }} />
          <MainStack.Screen name="History" component={ScreenHistory} options={{ title: '診断結果を見る' }} />
          <MainStack.Screen name="Login" component={ScreenLogin} options={{ title: 'ログイン' }} />
          <MainStack.Screen name="ResetPassword" component={ScreenResetPassword} options={{ title: 'パスワードリセット' }} />
        </MainStack.Navigator>
      </NavigationContainer>

      {/* ローディング */}
      {loading && <View style={AppConst.styles.loading}>
        <ActivityIndicator
          animating={true}
          color={AppConst.defaultColor}
          size="large" />
      </View>}
    </>
  );
}

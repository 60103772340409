import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, TouchableOpacity, View, SafeAreaView } from 'react-native';
import Constants from 'expo-constants';
import { RadioButton } from 'react-native-paper';
import * as CmnUtil from './CmnUtil';
import * as AppConst from './AppConst';
import * as SettingData from './SettingData';

const ScreenSetting = ({ navigation }: { navigation: any }) => {
  // 一発診断
  const [oneShot, setOneShot] = useState(SettingData.getOneShot());
  useEffect(() => {
    if(SettingData.getOneShot() == 'normal' && oneShot == 'oneShot'){
      setOneShotFlag(true);
    }
    SettingData.setOneShot(oneShot, () => { }, () => { });
  }, [oneShot]);

  // 一発診断メッセージ表示
  const [oneShotFlag, setOneShotFlag] = useState(false);

  // タップ回数カウント
  let tapCount = 0;
  let timer: any = 0;

  // バージョン情報クリック
  const onClickVersion = () => {
    // 初回タップ時にタップ回数リセットタイマー起動
    if (tapCount == 0) {
      timer = setTimeout(() => tapCount = 0, 1500);
    }

    // タップ回数インクリメント
    tapCount++;

    // 1.5秒以内に5回タップされたらモード選択画面起動
    if (tapCount >= 5) {
      navigation.navigate('Teacher');
      clearTimeout(timer);
      tapCount = 0;
    }
  }

  // アンケートクリック
  const onClickQuestionnaire = () => {
    CmnUtil.moveWebPage(AppConst.questionnaireUrl);
  }

  // ライセンス クリック
  const onClickLicense = () => {
    navigation.navigate('License');
  }

  // ラジオボタン描画
  const ViewRadio = ({ p_mode, p_label }: { p_mode: string, p_label: string }) => {
    return (
      <View style={{ flexDirection: 'row', height: 50 }}>
        <View style={styles.radio}>
          <RadioButton value={p_mode} color={AppConst.defaultColor} />
        </View>
        <TouchableOpacity style={[styles.radio, { width: '100%' }]} activeOpacity={1.0} onPress={() => setOneShot(p_mode)}>
          <Text style={{ width: '100%' }}>{p_label}</Text>
        </TouchableOpacity>
      </View>
    );
  }

  // 一発診断メッセージ
  const OneShotView = () => {
    return (
      <View style={styles.oneShot}>
        <View style={{ backgroundColor: 'white', maxWidth: 800, width: '90%', borderRadius: 5, padding: 20 }}>
          <Text style={AppConst.styles.fontSizeL}>本機能は検証用途であり、識別性能が低下する可能性があります。</Text>

          <TouchableOpacity
            style={{ height: 40, borderRadius: 10, borderWidth: 1, justifyContent: 'center', alignContent: 'center', marginTop: 15 }}
            onPress={() => { setOneShotFlag(false); }}>
            <Text style={{ textAlign: 'center' }}>OK</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }

  return (
    <SafeAreaView style={styles.container}>
      <View>
        <Text style={styles.settingHeader}>バージョン情報</Text>
        <TouchableOpacity style={styles.settingBody} activeOpacity={1.0} onPress={onClickVersion}>
          <Text>現在のバージョン　{Constants.manifest!.version}</Text>
        </TouchableOpacity>
      </View>
      <View>
        <Text style={styles.settingHeader}>作物・部位の選択</Text>
        <View style={styles.settingBodyOneShot}>
          <RadioButton.Group onValueChange={newValue => setOneShot(newValue)} value={oneShot}>
            <ViewRadio p_mode='normal' p_label='作物・部位を選択する' />
            <ViewRadio p_mode='oneShot' p_label='作物・部位を選択しない' />
          </RadioButton.Group>
        </View>
      </View>
      <View>
        <Text style={styles.settingHeader}>アンケート</Text>
        <TouchableOpacity style={styles.settingBody} onPress={onClickQuestionnaire}>
          <Text>アンケートページへ</Text>
        </TouchableOpacity>
      </View>
      <View>
        <Text style={styles.settingHeader}>ライセンス</Text>
        <TouchableOpacity style={styles.settingBody} onPress={onClickLicense}>
          <Text>ライセンス表示</Text>
        </TouchableOpacity>
      </View>

      {oneShotFlag && <OneShotView />}
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  settingHeader: {
    backgroundColor: '#dddddd',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
  },
  settingBody: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
  },
  settingBodyOneShot: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
  },
  radio: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  oneShot: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
});
export default ScreenSetting;
